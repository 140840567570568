import {
  Box,
  Typography,
  Radio,
  FormControlLabel,
  MuiThemeProvider,
  RadioGroup,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

// import { appTheme } from "../../../../riskAssessment/brmDetaails";
import { UpdateQuestionOption } from "../../../../../../actions/taskActions/TaskAction";
import { useEffect, useState } from "react";
import QuestionBody from "../../../../surveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";

const subText = [
  `Please be aware that a ""yes"" answer may imply your willingness to undergo a further security assessment for your service.`,
  `Please describe the specific access needs and protocols involved. if 'Yes' you maybe required to complete an additional Security Assessment). If ""No"" or ""Not Applicable,"" please provide an explanation. "`,
  `This may include the ability to view, modify, or manage the data. Please provide details of data access requirements and roles (controller/processor). If yes, be aware that your answers will be reviewed by our senior data privacy officer and may trigger a further data privacy assessment due to the sensitivity of the data involved.`,
];

const options = [
  "Strongly Disagree",
  "Disagree",
  "Neutral",
  "Agree",
  "Strongly Agree",
];

const QuestionsSection = ({
  question,
  activeTab,
  questionComment,
  children = false,
  fromSubmission,
  currentVersion,
}) => {
  // const dispatch = useDispatch();

  const [randomIndex, setRandomIndex] = useState(0);

  // let submittedAnswer = { option: question?.selectedOpt };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * subText.length);
    setRandomIndex(randomIndex);
  }, [question?._id]);

  // const handleOptionChange = (event) => {
  //   const selectedValue = event.target.value;
  //   dispatch(
  //     UpdateQuestionOption(question?._id, selectedValue, activeTab?._id)
  //   );
  // };

  return (
    <Box p={2}>
      <Box display={"flex"} flexDirection={"column"} sx={{ gap: 10 }}>
        {/* Question */}
        <Box
          style={{
            borderRadius: "8px",
            padding: "16px",
            border: "1px solid #E9E9E9",
            marginBottom:"10px"
          }}
        >
          <Typography
            style={{
              color: "#242424",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            {question?.question}
          </Typography>
        </Box>

        {/* disclamer */}
        {/* <Typography
          style={{
            color: "#878787",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {subText[randomIndex]}
        </Typography> */}
      </Box>

      {/* options */}
      <Box style={{ pointerEvents: question?.is_completed ? "none" : "all" }}>
        {children ? (
          children
        ) : (
          <QuestionBody
            questionType={question?.question_type}
            answers={question?.answers}
            submittedAnswer={question?.submitted_data}
            questionId={question?.question_id}
            activeTabId={activeTab?._id}
            isFrom={"taskSection"}
            removeEditOption={
              question?.question_type === "Location" ? false : true
            }
            is_completed={question?.is_completed}
            // table props
            isFor={"contribution"}
          />
        )}
        {/* <MuiThemeProvider theme={appTheme}>
          <RadioGroup
            aria-label="options"
            name="options"
            value={question?.selectedOpt}
            onChange={handleOptionChange}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio color="primary" />}
                label={option}
              />
            ))}
          </RadioGroup>
        </MuiThemeProvider> */}
      </Box>

      {question?.comment && (
        <Box
          style={{
            width: "100%",
            padding: "25px 25px",
          }}
        >
          <QuestionBody
            questionType={"Comment box"}
            questionId={question?.question_id}
            activeTabId={activeTab?._id}
            answers={
              question?.comment_value
                ? question?.comment_value
                : questionComment
            }
            isFrom={"questionComment"}
            comment_value={
              fromSubmission
                ? currentVersion?.comment_value
                : question?.comment_value
            }
            // answers={
            //   handleQuestionType === "Multichoice" ? questionData?.answers : null
            // }
            // checkBoxOption={checkBoxOption}
            // setCheckBoxOption={setCheckBoxOption}
          />
        </Box>
      )}
    </Box>
  );
};

export default QuestionsSection;
