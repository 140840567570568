import React, { useState } from "react";
import MoreIcon from "@material-ui/icons/MoreVert";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import CustomButton from "../../UI/button/button";
import logo from "../../../images/ImpactGrows Black corrected.svg";
import DefaultUser from "../../../images/Default_user.svg";
import OfficeLogo from "../../../images/Office.svg";
import ProfileCard from "../../widgets/profileCard/profileCard";
import UserProfileCard from "../../widgets/userProfileCard/userProfileCard";
import moment from "moment";
// import notificationDrawer from './NotificationDrawer'
import AppBarUseStyles from "./AppBarStyles";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

function CustomAppBar({
  isOpen,
  userName,
  userEmail,
  login,
  token,
  logOut,
  setUpdatePath,
  organisation_details,
  parent_organisation,
  surveyListing,
  viewAssessments,
  viewAssignedAssessments,
  user_type,
  setCurrentOrganzation,
  loginDetails,
  getNotifications,
  setNotification,
  setTotalNotification,
  setCurrentOrganisationUserType,
  setIsNewCompany,
  setSelectedOraganization,
  setSignupProcessNo,
  setIsSubscriptionActive,
  getOrganizations,
}) {
  const classes = AppBarUseStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [page, setPage] = React.useState({
    starting_after: 0,
    limit: 10,
  });

  const { supplier_data } = useSelector((state) => state?.login);

  function handleUserProfileCard() {
    setAnchorElUser(null);
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenu = (event) => {
    organisation_details.length > 0 && setAnchorEl(event.currentTarget);
  };

  const handleMenuUser = (event) => {
    if (event.currentTarget !== anchorElUser) {
      setAnchorElUser(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setNotification([]);
    setTotalNotification(0);
    setIsDrawerOpen(!isDrawerOpen);
    setPage({
      starting_after: 0,
      limit: 10,
    });
  };

  const OpenNotificationDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    if (!isDrawerOpen) {
      getNotifications(
        loginDetails.token,
        loginDetails.current_organisation,
        page.limit,
        page.starting_after
      );
      setPage({
        ...page,
        starting_after: page.starting_after + page.limit,
      });
    }
  };

  const handleLoadMore = () => {
    getNotifications(
      loginDetails.token,
      loginDetails.current_organisation,
      page.limit,
      page.starting_after
    );
    setPage({
      ...page,
      starting_after: page.starting_after + page.limit,
    });
  };
  const mobileMenuId = "primary-search-account-menu-mobile";

  // Notification pagination
  const [next, setNext] = useState(5);
  const notificationsPerPage = 5;
  let arrayForHoldingNotification = [];

  const loopWithSlice = (start, end) => {
    const slicedPosts = surveyListing.notifications.slice(start, end);
    arrayForHoldingNotification = [
      ...arrayForHoldingNotification,
      ...slicedPosts,
    ];
  };
  React.useEffect(() => {
    loopWithSlice(0, notificationsPerPage);
    getOrganizations(token, window.location.hostname);
  }, []);

  React.useEffect(() => {
    let selectedORG = undefined;
    if (organisation_details) {
      selectedORG = organisation_details.find(
        (ele) => ele._id === localStorage.getItem("current_organisation")
      );
    }

    if (selectedORG) {
      setSelectedOraganization(selectedORG);
      if (
        selectedORG.domain_logo &&
        localStorage.getItem("logo") !==
          selectedORG.domain_logo.after_login.logo
      )
        selectedORG.domain_logo &&
          localStorage.setItem(
            "logo",
            selectedORG.domain_logo.after_login.logo
          );
      selectedORG.domain_logo &&
        localStorage.setItem(
          "logo_styles",
          JSON.stringify(selectedORG.domain_logo.after_login.logo_styles)
        );
      localStorage.setItem("status", selectedORG.payment_status);
      localStorage.setItem("status_message", selectedORG.payment_message);
      setIsSubscriptionActive(
        selectedORG?.payment_status === "EXPIRED" ? false : true
      );
    }
  }, [organisation_details]);

  React.useEffect(() => {
    loopWithSlice(0, notificationsPerPage);
  }, []);
  // React.useEffect(() => {
  //   let selectedORG = undefined;
  //   if (organisation_details) {
  //     selectedORG = organisation_details.find(
  //       (ele) => ele._id === localStorage.getItem("current_organisation")
  //     );
  //   }
  //   if (selectedORG) setSelectedOraganization(selectedORG);
  // setSelectedOraganization(selectedORG);
  // localStorage.setItem("status", selectedORG?.payment_status);
  // localStorage.setItem("status_message", selectedORG?.payment_message);
  // setIsSubscriptionActive(selectedORG?.payment_status === "EXPIRED" ? false : true);
  // }, [organisation_details]);

  const handleShowMoreNotification = () => {
    loopWithSlice(0, next + notificationsPerPage);
    setNext(next + notificationsPerPage);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => handleMobileMenuClose()}>
        <IconButton>
          <Icon>home</Icon>
        </IconButton>
        <p>Home</p>
      </MenuItem>
      <MenuItem onClick={() => handleMobileMenuClose()}>
        <IconButton>
          <Badge variant="dot" overlap="circular" color="secondary">
            <Icon>notifications</Icon>
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
    </Menu>
  );

  const notificationDrawer = () => {
    console.log(surveyListing.notifications);
    return (
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        <div className={classes.mainDiv}>
          <Grid
            container
            justifyContent="center"
            className={classes.headerGrid}
          >
            <Grid item className={classes.subGrid}>
              <Typography variant="body1">Notification Panel</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ marginTop: 25 }}>
            {surveyListing.notifications.length > 0 ? (
              surveyListing.notifications.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <List>
                    <ListItem
                      key={item._id}
                      className={classes.notificationItem}
                    >
                      <ListItemIcon>
                        <Avatar
                          className={classes.avatar}
                          src={
                            item.created_by?.profile_img === ""
                              ? DefaultUser
                              : item.created_by?.profile_img?.url
                          }
                        ></Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          item.assessment_data.assessment_name !== ""
                            ? item.notification +
                              " in " +
                              item.assessment_data.assessment_name
                            : item.notification
                        }
                        secondary={
                          moment(item.created_on).format("DD/MM/YYYY") +
                          " " +
                          moment(item.created_on).format("HH:mm A")
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
              ))
            ) : (
              <div style={{ width: "100%" }}>
                <Typography style={{ textAlign: "center" }}>
                  No Notifications
                </Typography>
              </div>
            )}
            {surveyListing.totalNotifications > 10 &&
              surveyListing.totalNotifications >= page.starting_after && (
                <CustomButton
                  onClick={() => handleLoadMore()}
                  color="primary"
                  fullWidth
                  variant="contained"
                >
                  Load More
                </CustomButton>
              )}
          </Grid>
          {/* <Grid container justifyContent="center">
            <Grid
              item
              xs={6}
              className={classes.activitiesGrid}
            >
              <Typography
                onClick={handleShowMoreNotification}
                className={classes.activityTypo}
              >
                Load more activities
              </Typography>
            </Grid>
          </Grid> */}
        </div>
      </Drawer>
    );
  };
  return (
    <div className={classes.grow}>
      {isDrawerOpen && notificationDrawer()}
      <AppBar position="fixed" color="inherit">
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={11}>
            <Toolbar>
              {!isOpen &&
                loginDetails.selectedOrganization &&
                loginDetails.selectedOrganization.domain_logo &&
                loginDetails.selectedOrganization.domain_logo.after_login
                  .logo && (
                  <div
                    style={
                      loginDetails.selectedOrganization &&
                      loginDetails.selectedOrganization.domain_logo.after_login
                        .logo
                        ? loginDetails.selectedOrganization.domain_logo
                            .after_login.logo_styles
                        : JSON.parse(localStorage.getItem("logo_styles"))
                        ? JSON.parse(localStorage.getItem("logo_styles"))
                        : {}
                    }
                  >
                    {/* <div style={{ width:'175px', height:'60px',marginTop:'8px'}}> */}
                    <Link
                      to={
                        user_type === "SA"
                          ? "/survey_listing"
                          : "/report_listing"
                      }
                    >
                      <img
                        style={{
                          width: "80%",
                          height: "80%",
                          objectFit: "contain",
                        }}
                        src={
                          loginDetails.selectedOrganization &&
                          loginDetails.selectedOrganization.domain_logo
                            .after_login.logo
                            ? loginDetails.selectedOrganization.domain_logo
                                .after_login.logo
                            : localStorage.getItem("logo") &&
                              localStorage.getItem("logo")
                        }
                        alt="logo"
                      />
                    </Link>
                  </div>
                )}
              {!isOpen &&
                loginDetails.selectedOrganization &&
                loginDetails.selectedOrganization.domain_logo &&
                !loginDetails.selectedOrganization.domain_logo.after_login
                  .logo && (
                  <div>
                    {/* <div style={{ width:'175px', height:'60px',marginTop:'8px'}}> */}
                    <Link
                      to={
                        user_type === "SA"
                          ? "/survey_listing"
                          : "/report_listing"
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        style={{
                          width: "80%",
                          height: "80%",
                          objectFit: "contain",
                        }}
                        src={logo}
                        alt="logo"
                      />
                    </Link>
                  </div>
                )}
              <div className={classes.grow} />
              <div
                className={classes.sectionDesktop}
                data-tut="reactour__profile_card"
              >
                <IconButton
                  name="notification"
                  onClick={OpenNotificationDrawer}
                >
                  <Badge variant="dot" overlap="circular" color="secondary">
                    <Icon>notifications</Icon>
                  </Badge>
                </IconButton>

                {!login && (
                  <>
                    <Link to="/login" style={{ textDecoration: "none" }}>
                      <CustomButton
                        style={{ marginTop: 5 }}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setUpdatePath();
                        }}
                      >
                        Login
                      </CustomButton>
                    </Link>
                    &nbsp; &nbsp;
                    <Link to="/signup" style={{ textDecoration: "none" }}>
                      <CustomButton
                        style={{ marginTop: 5 }}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setUpdatePath();
                        }}
                      >
                        signup
                      </CustomButton>
                    </Link>
                  </>
                )}
                {login && (
                  <>
                    <div
                      className={classes.userImage}
                      onClick={handleMenuUser}
                      data-tut="reactour__profile_user"
                    >
                      <Avatar
                        size="small"
                        style={{ width: 35, height: 35 }}
                        src={
                          loginDetails.userProfile &&
                          loginDetails.userProfile?.url
                            ? loginDetails.userProfile?.url
                            : DefaultUser
                        }
                      ></Avatar>
                      <UserProfileCard
                        anchorEl={anchorElUser}
                        onClose={handleUserProfileCard}
                        userName={userName}
                        userEmail={userEmail}
                        token={token}
                        logOut={logOut}
                        loginDetails={loginDetails}
                      />
                    </div>
                    <div className={classes.border}>
                      <div
                        className={classes.name}
                        onClick={handleMenu}
                        data-tut="reactour__profile"
                      >
                        <Typography
                          variant="subtitle2"
                          style={{ marginRight: "20px" }}
                        >
                          {user_type === "SADMIN" || user_type === "SG"
                            ? supplier_data?.name
                            : loginDetails.selectedOrganization &&
                              loginDetails.selectedOrganization.company_name}
                        </Typography>
                        <Avatar
                          size="small"
                          className={classes.small}
                          src={
                            user_type === "SADMIN" || user_type === "SG"
                              ? supplier_data?.logo?.url
                              : loginDetails.selectedOrganization &&
                                loginDetails.selectedOrganization.logo
                              ? loginDetails.selectedOrganization.logo.url
                              : OfficeLogo
                          }
                        ></Avatar>
                      </div>
                      {open && user_type !== "SADMIN" && user_type !== "SG" && (
                        <ProfileCard
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          userName={userName}
                          userEmail={userEmail}
                          organisationDetails={organisation_details}
                          parent_organisation={parent_organisation}
                          viewAssessments={viewAssessments}
                          surveyListing={surveyListing}
                          token={token}
                          logOut={logOut}
                          viewAssignedAssessments={viewAssignedAssessments}
                          setCurrentOrganzation={setCurrentOrganzation}
                          loginDetails={loginDetails}
                          setCurrentOrganisationUserType={
                            setCurrentOrganisationUserType
                          }
                          setIsNewCompany={setIsNewCompany}
                          setSelectedOraganization={setSelectedOraganization}
                          setSignupProcessNo={setSignupProcessNo}
                          setIsSubscriptionActive={setIsSubscriptionActive}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>

                {login === false && (
                  <>
                    <CustomButton color="primary" variant="contained">
                      Login
                    </CustomButton>
                  </>
                )}
              </div>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
export default React.memo(CustomAppBar);
