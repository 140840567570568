import React from "react";

import StepHeader from "./stepHeader/StepHeader";
import SurveyStep1 from "./step1";
import SurveyStep2 from "./step2";
import SurveyStep3 from "./step3";
import SurveyStep4 from "./step4";
import SurveyButton from "./surveyButton/SurveyButton";
import FinalSubmission from "./finalStep/FinalSubmission";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  response1,
  response2,
} from "../../surveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
import { useDispatch, useSelector } from "react-redux";
import { submitAdminResponse } from "actions/supplierAdmin/supplierAdminActions";
import { setActiveStep } from "actions/surveyListing/surveyListingAction";

function getStepContent(step, response) {
  switch (step) {
    case 0:
      return <SurveyStep1 />;
    case 1:
      return <SurveyStep2 response={response} />;
    case 2:
      return <SurveyStep3 response={response} />;
    case 3:
      return <SurveyStep4 response={response} />;
    case 4:
      return <FinalSubmission />;
    default:
      return null;
  }
}

const steps = [
  "Add Stakeholder",
  "View & Assign Survey",
  "Audit & Freeze",
  "Submission",
];

const SurveySteps = () => {
  const { location } = useHistory();

  const dispatch = useDispatch();

  const { supplier_id, token, current_organisation } = useSelector(
    (state) => state?.login
  );
  const {
    selectedAssignSurvey,
    auditSelectedSection,
    auditSectionDetails,
    auditQuestionDetails,
    selectedCorporate
  } = useSelector((state) => state?.supplierAdmin);

  const { activeStep, surveyCompleted } = useSelector(
    (state) => state.surveyListing
  );

  const { pathname } = location;
  // console.log(history);

  // console.log(selectedAssignSurvey);

  const handleNext = (hasSubmit) => {
    if (hasSubmit) {
      dispatch(
        submitAdminResponse(
          token,
          supplier_id,
          selectedCorporate?._id,
          selectedAssignSurvey?.survey_id,
          selectedAssignSurvey?.frequency_id,
          selectedAssignSurvey?.is_default,
          selectedAssignSurvey?.version
        )
      );
      dispatch(setActiveStep(activeStep + 1));
    } else {
      dispatch(setActiveStep(activeStep + 1));
    }
  };

  const handleBack = () => {
    dispatch(setActiveStep(activeStep - 1));
  };

  return (
    <>
      {/* header */}
      <StepHeader steps={steps} activeStep={activeStep} />
      {/* main content */}
      {getStepContent(
        activeStep,
        pathname === "/vendor_admin/FlowSource/Survey_2_(Month_Feb)"
          ? response1
          : response2
      )}
      {/* proceed button */}
      <SurveyButton
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        surveyCompleted={surveyCompleted}
      />
    </>
  );
};
export default SurveySteps;
